<template>
    <AppLayout>
        <RequireLogin :isViewReady="isViewReady">
            <v-row justify="center" v-if="loading">
                <v-col cols="12" sm="12" md="6" lg="6" xl="6">
                    <div class="pa-5 text-center">
                        <p class="text-h5 font-weight-light">Loading...</p>
                    </div>
                </v-col>
            </v-row>
        </RequireLogin>
    </AppLayout>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import loginshield from '@/client';
import RequireLogin from '@/components/RequireLogin.vue';
import AppLayout from '@/components/AppLayout.vue';

const ajax = require('axios');
const { GatewayClient } = require('@cryptium/tigercomet-gateway-browser');

export default {
    components: {
        RequireLogin,
        AppLayout,
    },
    data() {
        return {
            interactionId: null,
            isViewReady: false,
            loading: false,
            resumeInteractionURL: null,
        };
    },
    methods: {
        async init() {
            console.log('LoginRedirect.vue: isReady: %o interactionId: %o', this.isReady, this.interactionId);
            const { csrfGuardToken } = this.session;
            console.log('LoginRedirect.vue: init: csrfGuardToken: %o', csrfGuardToken);

            console.log('loginredirect.vue: authenticated: %s', this.isAuthenticated);
            const gatewayClient = new GatewayClient({ ...loginshield.options, csrfGuardToken, storage: localStorage });

            if (this.resumeInteractionURL) {
                try {
                    const resumeInteractionResponse = await ajax.get(`${this.resumeInteractionURL}`, {
                        headers: {
                            Accept: 'application/json',
                        },
                    });
                    if (resumeInteractionResponse.data) {
                        console.log('loginredirect.vue: got data: %o', resumeInteractionResponse.data);
                        // example data: {"interactionId":"yVGA0dw5s4B6IUYUbxonKPJxoh_Rez2R","link":{"endpoint":"https://loginshield.com/service"}}
                        this.interactionId = resumeInteractionResponse.data.interactionId;
                    }
                } catch (err) {
                    console.log('loginredirect.vue: error while fetching forward url');
                }
            }

            // if we got here via session transfer token, the interactionId will be set,
            // send the gateway public key to the service
            if (this.interactionId) {
                this.loading = true;
                this.$store.commit('loading', { postGatewayPublicKey: true });
                console.log('loginredirect.vue: posting gateway public key for authenticated user');
                await gatewayClient.postGatewayPublicKey({ interactionId: this.interactionId });
                console.log('loginredirect.vue: reloading session for authenticated user');
                await this.$store.dispatch('init', { force: true });
                this.loading = false;
                this.$store.commit('loading', { postGatewayPublicKey: false });

                this.$router.push({ path: '/interaction', query: { i: this.interactionId } });
                return;
            }

            if (this.isAuthenticated) {
                console.log('loginredirect.vue: client is authenticated without interaction');
                this.$router.push('/dashboard');
            } else {
                console.log('loginredirect.vue: displaying login link for non-authenticated user');
                this.isViewReady = true;
            }
        },
    },
    computed: {
        ...mapState({
            isReady: (state) => state.isReady,
            session: (state) => state.session,
        }),
        ...mapGetters({
            currentAccount: 'account',
        }),
        isAuthenticated() {
            return this.session.isAuthenticated;
        },
        pendingAction() {
            return this.session.pendingAction;
        },
        pendingLinkRealmUser() {
            return this.session.pendingLinkRealmUser;
        },
    },
    watch: {
        isReady(value, oldValue) {
            console.log('loginredirect.vue: isReady changed from %o to %o', oldValue, value);
            // only call init again if ready changed from false to true after mounted()
            if (value && !oldValue) {
                this.init();
            }
        },
    },
    created() {
        const QUERY = this.$route.query;
        if (QUERY.loginshield) {
            console.log('got loginshield forward url: %s', QUERY.loginshield);
            this.resumeInteractionURL = QUERY.loginshield;
        } else if (QUERY.i) {
            console.log('got interaction id: %s', QUERY.i);
            this.interactionId = QUERY.i;
        }
    },
    mounted() {
        console.log('loginredirect.vue: mounted');
        if (this.isReady) {
            this.init();
        }
    },
};
</script>
