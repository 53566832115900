<template>
    <div>
        <div v-if="!isViewReady || !isReady">
            <v-row justify="center" class="pa-5">
                <p class="text-h5 font-weight-light">Loading...</p>
            </v-row>
        </div>
        <div v-if="isViewReady && isReady && isAuthenticated">
            <slot></slot>
        </div>
        <div v-if="isViewReady && isReady && !isAuthenticated">
            <v-row justify="center" class="pt-10">
                <p class="text-h5 font-weight-light">Login to continue</p>
            </v-row>
            <v-row justify="center" class="py-10">
                <v-btn elevation="6" class="blue white--text" v-on:click.prevent="login">
                    Login
                </v-btn>
            </v-row>
        </div>
    </div>
</template>

<script>
import { mapState } from 'vuex';

export default {
    props: {
        /*
        The parent component might have other work to do before
        deciding whether to show the authenticated or non-authenticated
        views. The LoginRedirect component might redirect the user to
        another page, and needs the progress indicator to show until
        the redirect is ready, instead of showing "login to continue"
        briefly. Most components don't need this, so the default is
        that to assume the parent component is ready and only depend
        on the isReady state from the Vuex store.
         */
        isViewReady: {
            type: Boolean,
            default: true,
        },
    },
    methods: {
        login() {
            const { path } = this.$router.currentRoute;
            console.log('requirelogin.vue: adding path to nav queue: %s');
            // we ask the login system to
            // redirect the user back to the same view after login, except when
            // the current path is login-redirect because that's not a
            // destination -- the user will end up there if needed, or else will
            // be redirected to the dashboard or to a previous view if there is
            // one already in the queue
            if (path === '/login-redirect') {
                this.$router.push({ path: '/login' });
            } else {
                // show the login page, and capture the current page with query parameters so we can return user here after login
                this.$router.push({ path: '/login', query: { from: this.$route.fullPath } });
            }
        },
    },
    computed: {
        ...mapState({
            isReady: (state) => state.isReady,
            session: (state) => state.session,
        }),
        isAuthenticated() {
            return this.session.isAuthenticated;
        },
    },
    mounted() {
        console.log('RequireLogin.vue: mounted');
    },
};
</script>
